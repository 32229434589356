/* eslint camelcase: off */
import { defineStore } from 'pinia';
import { useNuxtApp } from 'nuxt/app';
import { addEmptyDays } from '@@/utils/CommonUtils';

/**
 * When comparing locations and the lightWeight parameter is set to true in the payload then use
 * the light weight paths. e.g.: /forecasts/snow instead of /forecasts/snow-summary and
 * /history/snow instead of /history/snow-daily.
 */
const getLightWeightPath = (path, payload) => (payload?.lightWeight
  ? path.replace(/-.+$/, '')
  : path);

const getPath = (path, payload = {}) => {
  const { compareType, compareId } = payload;
  return compareType && compareId
    ? `/compare/${compareType}/${compareId}${getLightWeightPath(path, payload)}`
    : `/user/favorites/locations${getLightWeightPath(path, payload)}`;
};

const fetchForecast = async (params = {}) => {
  const { $api } = useNuxtApp();

  const {
    payload = {},
    path,
  } = params;

  const {
    days,
    exclude_zeros,
    limit = 20,
    location_type_ids,
    list_id,
    skip = 0,
    sort = payload.compareType ? 'alpha' : 'user',
    units = 'imperial',
    within,
  } = payload;

  const dir = sort.charAt(0) === '-' ? 'desc' : 'asc';
  const query = {
    days,
    dir,
    exclude_zeros,
    limit,
    location_type_ids,
    list_id,
    skip,
    sort: sort.replace(/^[+,-]/, ''), // Remove leading + or -
    units,
    within,
  };

  if (payload.fetchAll) {
    const response = await $api.get(path, query);

    query.skip += limit;

    while (response?.pagination?.has_more) {
      /* eslint no-await-in-loop: off */
      const nextResponse = await $api.get(path, query);

      response.locations = response.locations.concat(nextResponse.locations);
      response.pagination = nextResponse.pagination;
      query.skip += limit;
    }

    return response;
  }

  return $api.get(path, query);
};

const getState = () => ({
  cams: [],
  compare: null,
  favorites: [],
  forecastTempsHourly: [],
  forecastTempsHourlyRange: { max: undefined, min: undefined },
  forecastSnowSemiDailies: [],
  forecastSnowSemiDailyRange: { max: 9, min: 0 },
  forecastSnowSummaries: [],
  forecastSnowSummaryRange: { max: 9, min: 0 },
  forecastSummaries: [],
  historySnowDailies: [],
  historySnowRange: { max: 9, min: 0 },
  snowReports: [],
  ui: { scrollLeft: 0 },
});

export const useLocationsStore = defineStore('locations', {
  state: () => getState(),

  actions: {
    async fetchCams(payload = {}) {
      if (payload.resetLocations) {
        this.resetLocations(payload);
      }

      const path = getPath('/cams', payload);
      const response = await fetchForecast({
        self: this,
        payload,
        path,
      });

      const { locations } = response;

      // Add location name to each cam so that the <SnowCamsCard> can easily display the location
      // name in the caption without having to use the location_id field to look it up!
      locations
        .forEach((location) => location.cams.forEach((cam) => {
          cam.location_name = location.name;
        }));

      this.setForecast({
        cams: locations,
        refresh: payload.refresh,
      });

      this.setCompare({ ...payload, response });

      return locations.length;
    },

    async fetchForecastSnowSemiDaily(payload = {}) {
      if (payload.resetLocations) {
        this.resetLocations(payload);
      }

      const path = getPath('/forecasts/snow-semi-daily', payload);
      const response = await fetchForecast({
        self: this,
        payload,
        path,
      });

      const { locations, range_snow_semi_daily } = response;
      this.setForecast({
        forecastSnowSemiDailies: locations,
        forecastSnowSemiDailyRange: range_snow_semi_daily,
        refresh: payload.refresh,
      });

      this.setCompare({ ...payload, response });

      return locations.length;
    },

    async fetchForecastSnowSummary(payload = {}) {
      if (payload.resetLocations) {
        this.resetLocations(payload);
      }

      const path = getPath('/forecasts/snow-summary', payload);
      const response = await fetchForecast({
        self: this,
        payload,
        path,
      });

      const { locations, range_snow_daily } = response;

      if (payload?.saveToStore === false) {
        return { locations, range_snow_daily };
      }

      this.setForecast({
        forecastSnowSummaries: locations,
        forecastSnowSummaryRange: range_snow_daily,
        refresh: payload.refresh,
      });

      this.setCompare({ ...payload, response });

      return locations.length;
    },

    async fetchForecastSummary(payload = {}) {
      if (payload.resetLocations) {
        this.resetLocations(payload);
      }

      const path = getPath('/forecasts/summary', payload);
      const response = await fetchForecast({
        self: this,
        payload,
        path,
      });

      const { locations } = response;

      locations.forEach((location) => {
        if (location.forecast_daily?.length === 2) {
          addEmptyDays(location.forecast_daily);
        }
      });

      this.setForecast({
        forecastSummaries: locations,
        refresh: payload.refresh,
      });

      this.setCompare({ ...payload, response });

      return locations.length;
    },

    async fetchForecastTempsHourly(payload = {}) {
      if (payload.resetLocations) {
        this.resetLocations(payload);
      }

      const path = getPath('/forecasts/temps-hourly', payload);
      const response = await fetchForecast({
        self: this,
        payload,
        path,
      });

      const { locations, range_hourly } = response;
      this.setForecast({
        forecastTempsHourly: locations,
        forecastTempsHourlyRange: range_hourly,
        refresh: payload.refresh,
      });

      this.setCompare({ ...payload, response });

      return locations.length;
    },

    async fetchHistorySnowDaily(payload = {}) {
      if (payload.resetLocations) {
        this.resetLocations(payload);
      }

      const path = getPath('/history/snow-daily', payload);
      const response = await fetchForecast({
        self: this,
        payload,
        path,
      });

      const { locations, range_snow_daily } = response;

      if (payload?.saveToStore === false) {
        return { locations, range_snow_daily };
      }

      this.setForecast({
        historySnowDailies: locations,
        historySnowRange: range_snow_daily,
        refresh: payload.refresh,
      });

      this.setCompare({ ...payload, response });

      return locations.length;
    },

    async fetchSnowReports(payload = {}) {
      if (payload.resetLocations) {
        this.resetLocations(payload);
      }

      const path = getPath('/snow-reports', payload);
      const response = await fetchForecast({
        self: this,
        payload,
        path,
      });

      const { locations } = response;
      this.setForecast({
        snowReports: locations,
        refresh: payload.refresh,
      });

      this.setCompare({ ...payload, response });

      return locations.length;
    },

    resetLocations(payload = {}) {
      const { keepCompareOnReset = false } = payload;

      Object.entries(getState()).forEach(([key, value]) => {
        if (keepCompareOnReset && key === 'compare') {
          return;
        }

        this[key] = value;
      });
    },

    setCompare({ compareId, compareType, response = {} }) {
      if (!compareId || !compareType) {
        return;
      }

      const {
        countries,
        regions,
        season_passes,
        states,
      } = response;

      const compare = {
        compareType,
        compareId,
      };

      if (countries) {
        compare.countries = countries;
      }

      if (regions) {
        compare.regions = regions;
      }

      if (season_passes) {
        compare.season_passes = season_passes;
      }

      if (states) {
        compare.states = states;
      }

      this.compare = compare;
    },

    setFavorites(favorites) {
      this.favorites = favorites;
    },

    setForecast(payload) {
      Object.entries(payload).forEach(([key, values]) => {
        if (Array.isArray(this[key])) {
          if (payload.refresh) {
            values.forEach((value) => {
              const index = this[key].findIndex((item) => item.id === value.id);

              if (index !== -1) {
                this[key][index] = value;
              }
              else {
                this[key].push(value);
              }
            });
          }
          else {
            this[key].push(...values);
          }
        }
        else if (values?.precip_snow_max !== undefined && values?.precip_snow_min !== undefined) {
          const { precip_snow_max, precip_snow_min } = values;
          this[key].max = precip_snow_max;
          this[key].min = precip_snow_min;
        }
        else if (values?.temp_max !== undefined && values?.temp_min !== undefined) {
          const { temp_max, temp_min } = values;
          this[key].max = temp_max;
          this[key].min = temp_min;
        }
      });
    },

    setScrollLeft(scrollLeft) {
      this.ui.scrollLeft = scrollLeft;
    },
  },

  getters: {
    isCompareCountries(myState) {
      return myState.compare?.compareType === 'countries';
    },

    isCompareGlobal(myState) {
      return myState.compare?.compareType === 'global';
    },

    isCompareRegions(myState) {
      return myState.compare?.compareType === 'regions';
    },

    isCompareSeasonPasses(myState) {
      return myState.compare?.compareType === 'season-passes';
    },

    isCompareStates(myState) {
      return myState.compare?.compareType === 'states';
    },
  },
});
